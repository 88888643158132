var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("page-title"),
      _c(
        "div",
        { staticClass: "partition-area" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading.detail,
                  expression: "loading.detail"
                }
              ],
              ref: "appForm",
              attrs: {
                model: _vm.appForm,
                rules: _vm.rules,
                "label-width": "170px",
                "label-suffix": _vm.constants.labelSuffix
              }
            },
            [
              _c(
                "ics-page-inner",
                { attrs: { title: "运营企业信息" } },
                [
                  _c("template", { slot: "btn-inner" }, [
                    _c(
                      "div",
                      { staticClass: "btn-inner" },
                      [
                        _vm.editMode
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.editFirm }
                              },
                              [_vm._v(" 编辑 ")]
                            )
                          : _vm._e(),
                        !_vm.editMode
                          ? _c("el-button", { on: { click: _vm.cancelFirm } }, [
                              _vm._v(" 取消 ")
                            ])
                          : _vm._e(),
                        !_vm.editMode
                          ? _c(
                              "debounce-button",
                              {
                                attrs: {
                                  loading: _vm.loading.submit,
                                  type: "primary"
                                },
                                on: { click: _vm.submitForms }
                              },
                              [_vm._v(" 提交 ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "企业名称" } },
                        [
                          _c("ics-item-inner", {
                            attrs: {
                              prop: _vm.appForm.companyName,
                              format: _vm.utils.isEffectiveCommon
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "统一社会信用代码" } },
                        [
                          _c("ics-item-inner", {
                            attrs: {
                              prop: _vm.appForm.socialNo,
                              format: _vm.utils.isEffectiveCommon
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  !_vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "legalPerson",
                                label: "法定代表人姓名"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入法定代表人姓名" },
                                model: {
                                  value: _vm.appForm.legalPerson,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "legalPerson", $$v)
                                  },
                                  expression: "appForm.legalPerson"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "legalPerson",
                                label: "法定代表人姓名"
                              }
                            },
                            [
                              _c("ics-item-inner", {
                                attrs: {
                                  prop: _vm.appForm.legalPerson,
                                  format: _vm.utils.isEffectiveCommon
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "legalIdentity",
                                label: "法定代表人身份证号码"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入法定代表人身份证号码"
                                },
                                model: {
                                  value: _vm.appForm.legalIdentity,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "legalIdentity", $$v)
                                  },
                                  expression: "appForm.legalIdentity"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "legalIdentity",
                                label: "法定代表人身份证号码"
                              }
                            },
                            [
                              _c("ics-item-inner", {
                                attrs: {
                                  prop: _vm.appForm.legalIdentity,
                                  format: _vm.utils.isEffectiveCommon
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "注册资本(元)" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "number",
                                  placeholder: "请输入注册资本"
                                },
                                model: {
                                  value: _vm.appForm.regAmount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "regAmount", $$v)
                                  },
                                  expression: "appForm.regAmount"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "注册资本(元)" } },
                            [
                              _c("ics-item-inner", {
                                attrs: {
                                  prop: _vm.appForm.regAmount,
                                  format: function(val) {
                                    return _vm.utils.moneyFormat(val, 2)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "固定电话" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入固定电话" },
                                model: {
                                  value: _vm.appForm.tel,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "tel", $$v)
                                  },
                                  expression: "appForm.tel"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "固定电话" } },
                            [
                              _c("ics-item-inner", {
                                attrs: {
                                  prop: _vm.appForm.tel,
                                  format: _vm.utils.isEffectiveCommon
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "电子邮箱" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入电子邮箱" },
                                model: {
                                  value: _vm.appForm.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "email", $$v)
                                  },
                                  expression: "appForm.email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "电子邮箱" } },
                            [
                              _c("ics-item-inner", {
                                attrs: {
                                  prop: _vm.appForm.email,
                                  format: _vm.utils.isEffectiveCommon
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "传真" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入传真" },
                                model: {
                                  value: _vm.appForm.fax,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "fax", $$v)
                                  },
                                  expression: "appForm.fax"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "传真" } },
                            [
                              _c("ics-item-inner", {
                                attrs: {
                                  prop: _vm.appForm.fax,
                                  format: _vm.utils.isEffectiveCommon
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "contactName",
                                label: "联系人姓名"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入联系人姓名" },
                                model: {
                                  value: _vm.appForm.contactName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "contactName", $$v)
                                  },
                                  expression: "appForm.contactName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "contactName",
                                label: "联系人姓名"
                              }
                            },
                            [
                              _c("ics-item-inner", {
                                attrs: {
                                  prop: _vm.appForm.contactName,
                                  format: _vm.utils.isEffectiveCommon
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "contactPhone",
                                label: "联系人电话"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入联系人电话" },
                                model: {
                                  value: _vm.appForm.contactPhone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "contactPhone", $$v)
                                  },
                                  expression: "appForm.contactPhone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "contactPhone",
                                label: "联系人电话"
                              }
                            },
                            [
                              _c("ics-item-inner", {
                                attrs: {
                                  prop: _vm.appForm.contactPhone,
                                  format: _vm.utils.isEffectiveCommon
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "公司网站" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入公司网站" },
                                model: {
                                  value: _vm.appForm.net,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "net", $$v)
                                  },
                                  expression: "appForm.net"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "公司网站" } },
                            [
                              _c("ics-item-inner", {
                                attrs: {
                                  prop: _vm.appForm.net,
                                  format: _vm.utils.isEffectiveCommon
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "usEnterpriseAddress",
                                label: "注册地址"
                              }
                            },
                            [
                              _c("address-picker", {
                                attrs: {
                                  "is-show-address-info": _vm.isShowAddressInfo
                                },
                                model: {
                                  value: _vm.appForm.usEnterpriseAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.appForm,
                                      "usEnterpriseAddress",
                                      $$v
                                    )
                                  },
                                  expression: "appForm.usEnterpriseAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "注册地址" } },
                            [
                              _c("address-picker-detail", {
                                model: {
                                  value: _vm.appForm.usEnterpriseAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.appForm,
                                      "usEnterpriseAddress",
                                      $$v
                                    )
                                  },
                                  expression: "appForm.usEnterpriseAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "dsEnterpriseAddress",
                                label: "办公地址"
                              }
                            },
                            [
                              _c("address-picker", {
                                attrs: {
                                  "is-show-address-info": _vm.isShowAddressInfo
                                },
                                model: {
                                  value: _vm.appForm.dsEnterpriseAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.appForm,
                                      "dsEnterpriseAddress",
                                      $$v
                                    )
                                  },
                                  expression: "appForm.dsEnterpriseAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "办公地址" } },
                            [
                              _c("address-picker-detail", {
                                model: {
                                  value: _vm.appForm.dsEnterpriseAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.appForm,
                                      "dsEnterpriseAddress",
                                      $$v
                                    )
                                  },
                                  expression: "appForm.dsEnterpriseAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "经营范围" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入经营范围(限300字)",
                                  maxlength: 300,
                                  type: "textarea"
                                },
                                model: {
                                  value: _vm.appForm.scope,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "scope", $$v)
                                  },
                                  expression: "appForm.scope"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editMode
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "经营范围" } },
                            [
                              _c("ics-item-inner", {
                                attrs: {
                                  prop: _vm.appForm.scope,
                                  format: _vm.utils.isEffectiveCommon
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }