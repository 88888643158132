<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <el-form ref="appForm" v-loading="loading.detail" :model="appForm" :rules="rules" label-width="170px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="运营企业信息">
          <template slot="btn-inner">
            <div class="btn-inner">
              <el-button v-if="editMode" type="primary" @click="editFirm">
                编辑
              </el-button>
              <el-button v-if="!editMode" @click="cancelFirm">
                取消
              </el-button>
              <debounce-button v-if="!editMode" :loading="loading.submit" type="primary" @click="submitForms">
                提交
              </debounce-button>
            </div>
          </template>
          <el-col :span="12">
            <el-form-item label="企业名称">
              <ics-item-inner :prop="appForm.companyName" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="统一社会信用代码">
              <ics-item-inner :prop="appForm.socialNo" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col v-if="!editMode" :span="12">
            <el-form-item prop="legalPerson" label="法定代表人姓名">
              <el-input v-model="appForm.legalPerson" placeholder="请输入法定代表人姓名" />
            </el-form-item>
          </el-col>
          <el-col v-if="editMode" :span="12">
            <el-form-item prop="legalPerson" label="法定代表人姓名">
              <ics-item-inner :prop="appForm.legalPerson" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col v-if="!editMode" :span="12">
            <el-form-item prop="legalIdentity" label="法定代表人身份证号码">
              <el-input v-model="appForm.legalIdentity" placeholder="请输入法定代表人身份证号码" />
            </el-form-item>
          </el-col>
          <el-col v-if="editMode" :span="12">
            <el-form-item prop="legalIdentity" label="法定代表人身份证号码">
              <ics-item-inner :prop="appForm.legalIdentity" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col v-if="!editMode" :span="12">
            <el-form-item label="注册资本(元)">
              <el-input v-model="appForm.regAmount" type="number" placeholder="请输入注册资本" />
            </el-form-item>
          </el-col>
          <el-col v-if="editMode" :span="12">
            <el-form-item label="注册资本(元)">
              <ics-item-inner :prop="appForm.regAmount" :format="(val)=>utils.moneyFormat(val,2)" />
            </el-form-item>
          </el-col>
          <el-col v-if="!editMode" :span="12">
            <el-form-item label="固定电话">
              <el-input v-model="appForm.tel" placeholder="请输入固定电话" />
            </el-form-item>
          </el-col>
          <el-col v-if="editMode" :span="12">
            <el-form-item label="固定电话">
              <ics-item-inner :prop="appForm.tel" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col v-if="!editMode" :span="12">
            <el-form-item label="电子邮箱">
              <el-input v-model="appForm.email" placeholder="请输入电子邮箱" />
            </el-form-item>
          </el-col>
          <el-col v-if="editMode" :span="12">
            <el-form-item label="电子邮箱">
              <ics-item-inner :prop="appForm.email" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col v-if="!editMode" :span="12">
            <el-form-item label="传真">
              <el-input v-model="appForm.fax" placeholder="请输入传真" />
            </el-form-item>
          </el-col>
          <el-col v-if="editMode" :span="12">
            <el-form-item label="传真">
              <ics-item-inner :prop="appForm.fax" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col v-if="!editMode" :span="12">
            <el-form-item prop="contactName" label="联系人姓名">
              <el-input v-model="appForm.contactName" placeholder="请输入联系人姓名" />
            </el-form-item>
          </el-col>
          <el-col v-if="editMode" :span="12">
            <el-form-item prop="contactName" label="联系人姓名">
              <ics-item-inner :prop="appForm.contactName" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col v-if="!editMode" :span="12">
            <el-form-item prop="contactPhone" label="联系人电话">
              <el-input v-model="appForm.contactPhone" placeholder="请输入联系人电话" />
            </el-form-item>
          </el-col>
          <el-col v-if="editMode" :span="12">
            <el-form-item prop="contactPhone" label="联系人电话">
              <ics-item-inner :prop="appForm.contactPhone" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col v-if="!editMode" :span="24">
            <el-form-item label="公司网站">
              <el-input v-model="appForm.net" placeholder="请输入公司网站" />
            </el-form-item>
          </el-col>
          <el-col v-if="editMode" :span="24">
            <el-form-item label="公司网站">
              <ics-item-inner :prop="appForm.net" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col v-if="!editMode" :span="12">
            <el-form-item prop="usEnterpriseAddress" label="注册地址">
              <address-picker v-model="appForm.usEnterpriseAddress" :is-show-address-info="isShowAddressInfo" />
            </el-form-item>
          </el-col>
          <el-col v-if="editMode" :span="12">
            <el-form-item label="注册地址">
              <address-picker-detail v-model="appForm.usEnterpriseAddress" />
            </el-form-item>
          </el-col>
          <el-col v-if="!editMode" :span="12">
            <el-form-item prop="dsEnterpriseAddress" label="办公地址">
              <address-picker v-model="appForm.dsEnterpriseAddress" :is-show-address-info="isShowAddressInfo" />
            </el-form-item>
          </el-col>
          <el-col v-if="editMode" :span="12">
            <el-form-item label="办公地址">
              <address-picker-detail v-model="appForm.dsEnterpriseAddress" />
            </el-form-item>
          </el-col>
          <el-col v-if="!editMode" :span="24">
            <el-form-item label="经营范围">
              <el-input v-model="appForm.scope" placeholder="请输入经营范围(限300字)" :maxlength="300" type="textarea" />
            </el-form-item>
          </el-col>
          <el-col v-if="editMode" :span="24">
            <el-form-item label="经营范围">
              <ics-item-inner :prop="appForm.scope" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
        </ics-page-inner>
      </el-form>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import AddressPicker from '@/components/template/address-picker'
import AddressPickerDetail from '@/components/template/address-picker-detail'
export default {
  components: {AddressPicker, AddressPickerDetail},
  mixins: [routeEnterMixin],
  data () {
    return {
      isShowAddressInfo: false,
      appForm: {},
      editMode: true,
      loading: {
        detail: false
      }
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.loading.detail = true
      this.api.company.channel.getChannelDetail().then(result => {
        const data = result.data.data || {}
        let regAddr = []
        let workAddr = []
        if (data.firm.regAddr) {
          regAddr = data.firm.regAddr.split(',')
          data.firm.usEnterpriseAddress = [regAddr[0], regAddr[1], regAddr[2], data.firm.regAddrDetail]
        }
        if (data.firm.workAddr) {
          workAddr = data.firm.workAddr.split(',')
          data.firm.dsEnterpriseAddress = [workAddr[0], workAddr[1], workAddr[2], data.firm.workAddrDetail]
        }
        this.appForm = data.firm || {}
        this.isShowAddressInfo = false
      }).finally(() => {
        this.loading.detail = false
      })
    },
    editFirm () {
      this.getDetail()
      this.editMode = false
    },
    cancelFirm () {
      this.getDetail()
      this.editMode = true
    },
    viewEdit () {
      if (this.editMode === 'edit') {
        return true
      } else {
        return false
      }
    },
    submitForms () {
      this.$refs.appForm.validate((valid) => {
        if (valid) {
          this.loading.submit = true
          const data = this.appForm
          let regAddr = ''
          let regAddrDetail = ''
          let workAddr = ''
          let workAddrDetail = ''
          if (data.usEnterpriseAddress !== undefined && data.usEnterpriseAddress.length > 0) {
            const regAddr1 = [data.usEnterpriseAddress[0], data.usEnterpriseAddress[1], data.usEnterpriseAddress[2]]
            regAddr = regAddr1.join(',')
            regAddrDetail = data.usEnterpriseAddress[3]
          }
          if (data.dsEnterpriseAddress !== undefined && data.dsEnterpriseAddress.length > 0) {
            const workAddr1 = [data.dsEnterpriseAddress[0], data.dsEnterpriseAddress[1], data.dsEnterpriseAddress[2]]
            workAddr = workAddr1.join(',')
            workAddrDetail = data.dsEnterpriseAddress[3]
          }
          const info = {
            contactName: data.contactName,
            contactPhone: data.contactPhone,
            email: data.mail,
            regAmount: data.regAmount,
            tel: data.tel,
            fax: data.fax,
            id: data.id,
            scope: data.scope,
            legalPerson: data.legalPerson,
            legalIdentity: data.legalIdentity,
            remark: data.remark,
            net: data.net,
            regAddr: regAddr,
            regAddrDetail: regAddrDetail,
            workAddr: workAddr,
            workAddrDetail: workAddrDetail
          }
          this.api.company.channel.updateChannel(info).then(result => {
            if (result.data.success === true) {
              this.loading.submit = false
              this.$message.success('保存成功')
              this.editMode = true
              this.getDetail()
            } else {
              this.$message.error(result.data.message)
            }
          }).catch(e => {
            this.loading.submit = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
